// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
require("@rails/ujs").start();
require("@rails/activestorage").start();
require("channels");
require('datatables.net');
require('datatables.net-bs4');
require('datatables.net-buttons-bs4');
require('select2');
var jQuery = require("jquery"); // import jQuery from "jquery";
require("jquery-ui");
require('moment');
require('daterangepicker'); // more settings http://www.daterangepicker.com
require('icheck-bootstrap'); // more settings https://www.npmjs.com/package/icheck-bootstrap

var Turbolinks = require("turbolinks");
Turbolinks.start();
global.$ = global.jQuery = jQuery;
window.$ = window.jQuery = jQuery;
require('bootstrap');
require('admin-lte');
var Plyr = require('plyr');
global.Plyr = Plyr;
window.d3 = require('d3');
window.c3 = require('c3');
let Chart = require('chart.js');
window.Chart = Chart;

const cocoon = import('cocoon-js');

var Inputmask = require('inputmask');

require('common/data_tables.js');
require('common/date_picker.js');
require('common/copy_btn.js');
require('clients/common.js');
require('clients/invoice_detail.js');
require('clients/invoice.js');
require('clients/origin_calls.js');
require('clients/mobile_app_voice_records.js');
require('clients/phone_licences.js');
require('clients/integration.js');
require('clients/ivr.js');
require('clients/client_employees.js');
require('clients/settings.js');


require('clients/reports/shared.js');
require('clients/reports/employees_report.js');
