$(function () {
  function getRuDateWeight(dateStr) {
    if (dateStr.match(/\./)) {
      var dateSplitted = dateStr.split('.');
      var Year = dateSplitted[2];
      var Month = dateSplitted[1];
      var Day = dateSplitted[0];
      var date_in_seconds = new Date(Year, parseInt(Month) - 1, Day).getTime()
      return date_in_seconds
    } else {
      return 0
    }
  }

  function getRuTimeWeight(dateStr) {
    if (dateStr.match(/\s/)) {
      var dateSplitted = dateStr.split(' ')[0].split('.');
      var timeSplitted = dateStr.split(' ')[1].split(':');
      var Year = dateSplitted[2];
      var Month = dateSplitted[1];
      var Day = dateSplitted[0];
      var Minute = timeSplitted[1];
      var Hour = timeSplitted[0];
      var datetime_in_seconds = new Date(Year, parseInt(Month) - 1, Day, Hour, Minute).getTime()
      return datetime_in_seconds
    } else {
      return 0
    }
  }

//#translate table start
const currentLocale = getCurrentLocale();
let url;

if (currentLocale && currentLocale.startsWith('fr')) {
  url = '//cdn.datatables.net/plug-ins/1.13.6/i18n/fr-FR.json';
} else {
  url = '//cdn.datatables.net/plug-ins/1.13.6/i18n/en-GB.json';
}

$.extend($.fn.dataTable.defaults, {
  language: {
    url: url
  }
});
//#translate table end

  jQuery.fn.dataTableExt.oSort['ru_date_time-asc'] = function (a, b) {
    return ((getRuTimeWeight(a) < getRuTimeWeight(b)) ? -1 : 1);
  };

  jQuery.fn.dataTableExt.oSort['ru_date_time-desc'] = function (a, b) {
    return ((getRuTimeWeight(a) > getRuTimeWeight(b)) ? -1 : 1);
  };

  jQuery.fn.dataTableExt.oSort['ru_date-asc'] = function (a, b) {
    return ((getRuDateWeight(a.split(' ')[0]) < getRuDateWeight(b.split(' ')[0])) ? -1 : 1);
  };

  jQuery.fn.dataTableExt.oSort['ru_date-desc'] = function (a, b) {
    return ((getRuDateWeight(a.split(' ')[0]) > getRuDateWeight(b.split(' ')[0])) ? -1 : 1);
  };

  /*------ Admin Panel begin ------*/
  $("#clients_table").DataTable({
    "responsive": true,
    "autoWidth": false,
    "aaSorting": [],
    "pageLength": 100
  });

  $("#agents_table").DataTable({
    "responsive": true,
    "autoWidth": false
  });

  $("#voip_files_table").DataTable({
    "responsive": true,
    "autoWidth": false
  });

  $("#tariffs_table").DataTable({
    "responsive": true,
    "autoWidth": false,
    'iDisplayLength': 10
  });

  $("#billing_cycle_products_charge_table").DataTable({
    "responsive": true,
    "autoWidth": false,
    'iDisplayLength': 10
  });

  $("#billing_cycle_service_charge_table").DataTable({
    "responsive": true,
    "autoWidth": false,
    'iDisplayLength': 10
  });


  $("#client_activities_table").DataTable({
    "responsive": true,
    "autoWidth": false,
    'iDisplayLength': 25,
    "order": [[ 0, "desc" ]]
  });

  $("#balance_change_increase_table").DataTable({
    "responsive": true,
    "autoWidth": false,
    'iDisplayLength': 25,
    "order": [[ 1, "desc" ]],
    'columnDefs': [
      {'sType': 'ru_date_time', 'aTargets': [1]}
    ]
  });

  $("#activities-charge-attempt").DataTable({
    "responsive": true,
    "autoWidth": false,
    'iDisplayLength': 25,
    'columnDefs': [
      {'sType': 'ru_date_time', 'aTargets': [0]}
    ]
  });

  

  $('#client_phone_applications_table').DataTable({
    "responsive": true,
    "autoWidth": false,
    'iDisplayLength': 50,
    "order": [],
    'columnDefs': [
      {'orderable': false, 'targets': 'no-sort'},
      {'sType': 'ru_date_time', 'aTargets': [4]}
    ]
  });

  $('#strange_calls').DataTable({
    "responsive": true,
    "autoWidth": false,
    'iDisplayLength': 25,
    "order": [[ 0, "desc" ]],
    'columnDefs': [
      {'sType': 'ru_date_time', 'aTargets': [0]}
    ]
  });

  $('#system_errors_table').DataTable({
    "responsive": true,
    "autoWidth": false,
    'iDisplayLength': 25,
    "order": [[ 1, "desc" ]],
    'columnDefs': [
      { 'sType': 'ru_date_time', 'aTargets': [1] },
      { 'orderable': false, 'targets': 'no-sort' }
    ]
  });

  $('#sims_table').DataTable({
    "responsive": true,
    "autoWidth": false,
    'iDisplayLength': 25,
    "order": [[ 1, "desc" ]],
    'columnDefs': [
      { 'orderable': false, 'targets': 'no-sort' }
    ]
  });

  $('#sim_orders_table').DataTable({
    "responsive": true,
    "autoWidth": false,
    'iDisplayLength': 25,
    "order": [[ 1, "desc" ]],
    'columnDefs': [
      { 'orderable': false, 'targets': 'no-sort' }
    ]
  });

  /*------ Admin Panel end ------*/

  /*------ Client begin ------*/
  $("#phones_table").DataTable({
    "responsive": true,
    "autoWidth": false,
    'iDisplayLength': 25,
    "order": [],
    'columnDefs': [
      {'orderable': false, 'targets': 'no-sort'},
      {'sType': 'ru_date', 'aTargets': [3]},
      {'sType': 'ru_date', 'aTargets': [4]}
    ]
  });

  $("#client_mobile_app_managers_table").DataTable({
    "responsive": true,
    "autoWidth": false,
    'iDisplayLength': 50,
    "order": [],
    'columnDefs': [
      {'orderable': false, 'targets': 'no-sort'},
      {'sType': 'ru_date_time', 'aTargets': [5]}
    ]
  });

  $("#inactive_phones-table").DataTable({
    "responsive": true,
    "autoWidth": false,
    'iDisplayLength': 25,
    "order": [],
    'columnDefs': [
      {'orderable': false, 'targets': 'no-sort'},
      {'sType': 'ru_date', 'aTargets': [3]},
      {'sType': 'ru_date', 'aTargets': [4]}
    ]
  });

  $("#payments_report").DataTable({
    "responsive": true,
    "autoWidth": false,
    'iDisplayLength': 25,
    "order": [],
    'columnDefs': []
  });

  $('#invoices-table').DataTable({
    "responsive": true,
    "autoWidth": false,
    'iDisplayLength': 10,
    "order": [[ 0, "desc" ]],
    'columnDefs': [
      {'sType': 'ru_date_time', 'aTargets': [0]}
    ]
  });

  $('#client_acts_table').DataTable({
    "responsive": true,
    "autoWidth": false,
    'iDisplayLength': 25,
    "order": [[ 0, "desc" ]],
    'columnDefs': []
  });
  /*------ Client end ------*/
});
