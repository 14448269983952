$(document).ready(function() {
  $(document).on('cocoon:before-insert', '#tags_tbody', function(e, insertedItem) {
    const existedColors = $.map($('#tags_tbody input[type="color"]'), (el) => el.value.toUpperCase());

    $(insertedItem).find('input[type="color"]').val(randomColor(existedColors));
  })
})

function randomColor(blackList = []) {
  const hexCharacters = [0,1,2,3,4,5,6,7,8,9,"A","B","C","D","E","F"]
  let newColor = '#'

  for (let index = 0; index < 6; index++){
    const randomPosition = Math.floor ( Math.random() * hexCharacters.length )
    newColor += hexCharacters[randomPosition]
  }
  if (!blackList.includes(newColor)) {
    return newColor;
  }

  return randomColor(blackList);
}
