window.PriceCalculator = class {

    constructor(licence_count, one_licence_price, one_licence_month_price, prices) {
        this.licence_count = licence_count
        this.one_licence_price = one_licence_price
        this.prices = prices
        this.one_licence_month_price = one_licence_month_price
    }

    get licence_count() {
        return this._licence_count
    }

    set licence_count(count) {
        this._licence_count = count
    }

    get one_licence_price() {
        return this._one_licence_price
    }

    set one_licence_price(price) {
        this._one_licence_price = price
    }

    get prices() {
        return this._prices
    }

    set prices(prices) {
        this._prices = prices
    }

    getPriceOfProlangation() {
        if ($('#with_prolongation').is(":checked")) {
            return parseFloat(this.licence_count * this.fetchPriceWithDiscount())
        } else {
            return 0
        }
    }

    getVoiceStaragePrice() {
        if ($('#with_voice_storage').is(":checked")) {
            return parseFloat((this.getAmountOfPhones() * this.fetchPriceWithDiscount() + this.getPriceOfProlangation()) * 0.10)
        } else {
            return 0
        }
    }

    getAmountOfPhones() {
        let amount_of_phones = parseInt($('input#amount_of_phones').val()); // количество подключаемых лицензий
        if (amount_of_phones === undefined || isNaN(amount_of_phones) || amount_of_phones < 1) {
            return 0
        } else {
            return parseInt(amount_of_phones)
        }
    }

    getPriceByDefaultTariff() {
        return this.one_licence_price * this.getAmountOfPhones() // сумма за подключаемые лизензий по базовому тарифу
    }

    getPhoneLicenceCountAfterConnection() {
        return this.licence_count + this.getAmountOfPhones() // количество лицензий с учётом добавленных
    }

    fetchNextPriceAndLicenceCountForNextPrice() {
        let array_of_prices = Object.values(this.prices).reverse()
        let array_of_counts = Object.keys(this.prices).reverse()

        for (i in array_of_counts) {
            if (calculator.getPhoneLicenceCountAfterConnection() >= array_of_counts[i]) {
                return {
                    next_price: array_of_prices[i - 1],
                    licence_count_for_next_price: (array_of_counts[i - 1] - calculator.getPhoneLicenceCountAfterConnection())
                }
                break;
            }
        }
    }

    fetchPriceWithDiscount() {
        return this.one_licence_month_price
    }

    fetchPriceForPay() {
        return this.getAmountOfPhones() * this.fetchPriceWithDiscount() + this.getPriceOfProlangation() + this.getVoiceStaragePrice()//сумма к оплате
    }

    fetchDiscountAmount() {
        let res = (this.getAmountOfPhones() * this.fetchPriceWithDiscount()) - this.getPriceByDefaultTariff()//сумма скидки
        return res > 0 ? res : 0
    }

    setVoiceStorageValues() {
        if ($('#with_voice_storage').is(":checked")) {
            $('.voiceStorageAmount, .with_voice_storage').show()
            $('.voiceStorageAmount').text(this.getVoiceStaragePrice().toFixed(2))
        } else {
            $('.voiceStorageAmount, .with_voice_storage').hide()
            $('.voiceStorageAmount').text('0')
        }
    }

    setProlangationValues() {
        if ($('#with_prolongation').is(":checked")) {
            $('.prolongationAmount, .with_prolongation').show()
            $('.prolongationAmount').text(this.getPriceOfProlangation().toFixed(2))
        } else {
            $('.prolongationAmount, .with_prolongation').hide()
            $('.prolongationAmount').text('0')
        }
    }
}

window.calculateLicencePrices = function () {
    // if(isNaN(calculator.fetchNextPriceAndLicenceCountForNextPrice()["next_price"])){
    //   $('#priceDiscountInfo').hide(500)
    // }else{
    //   $('#priceDiscountInfo').show(500)
    // }

    $('span#amountByBaseTariff').text(parseFloat(calculator.getPriceByDefaultTariff()).toFixed(2));
    $('span#finalPrice').text(parseFloat(calculator.getPriceByDefaultTariff()).toFixed(2));
    $('.licences-price-total').text(parseFloat(calculator.fetchPriceForPay()).toFixed(2));
    $('span#discountByCountLicence').text(parseFloat(calculator.fetchDiscountAmount()).toFixed(2))
    // $('span#nextPrice').text(parseFloat(calculator.fetchNextPriceAndLicenceCountFor/NextPrice()["next_price"]).toFixed(2))
    // $('span#licenceCountForNextPrice').text(calculator.fetchNextPriceAndLicenceCountForNextPrice()["licence_count_for_next_price"])
};

$(document).on('change', '#with_prolongation', function () {
    calculator.setProlangationValues()
    calculator.setVoiceStorageValues()
    window.calculateLicencePrices()
});

$(document).on('change', '#with_voice_storage', function () {
    calculator.setVoiceStorageValues()
    window.calculateLicencePrices()
});

$(document).on('change', '#amount_of_phones', function () {
    calculator.setProlangationValues()
    calculator.setVoiceStorageValues()
    window.calculateLicencePrices()
});

$(document).on('cocoon:after-insert', '#client_phone_request_instance_fields_cont', function (e, insertedItem, originalEvent) {
    window.calculateLicencePrices();
    window.initFormInputs()
});

$(document).on('cocoon:after-remove', '#client_phone_request_instance_fields_cont', function (e, insertedItem, originalEvent) {
    window.calculateLicencePrices()
});

$(document).on('change', '.discount-percent-radio', function () {
    $('.licence-length-select-cont').removeClass('selected-length-select-cont');
    $(this).parents('.licence-length-select-cont').addClass('selected-length-select-cont');
    window.calculateLicencePrices()
});

$(document).on('hide.bs.modal', '#add_phones_confirm_dialog', function () {
    $('#add_phones_dialog').css('z-index', 1050)
});

$(document).on('show.bs.modal', '#add_phones_confirm_dialog', function () {
    $('#add_phones_dialog').css('z-index', 999)
});

$(document).on('ready', function () {
    let $inactivePhones = $('#inactive-phones')
    $inactivePhones.on('show.bs.collapse', function () {
        let $array = $('.inactive .array')
        $array.removeClass('toggle-array-down')
        $array.addClass('toggle-array-up')
    })

    $inactivePhones.on('hidden.bs.collapse', function () {
        let $array = $('.inactive .array')
        $array.removeClass('toggle-array-up')
        $array.addClass('toggle-array-down')
    })
})


$(document).on('click', '.activate_order_btn', function() {
  const $this = $(this);
  $("#activating_modal .licences-price-total").text($this.data("price"));
  $("#activate_confirm_btn").attr("href", $this.data("url"));
});

$(document).on("input", "#sim_order_amount", function () {
  const simPrice = $("#one_sim_price").val();
  const month = $("#annual_tariff").val() === "true" ? 12 : 1;
  const price = (parseFloat(simPrice) * parseFloat($(this).val() || 0)) * month;
  $("#sim-order-modal .licences-price-total").text(price);
});

$(document).on("change", ".allow_voicemail_checkbox", function () {
  $("select[name*=no_answer_file_id]").attr("disabled", $(this).is(":checked"));
  $("select[name*=voicemail_file_id]").attr("disabled", !$(this).is(":checked"));
});
