$(function () {

    const languageSettings = {
        en: {
            singleDatetimeRangePicker: {
                "singleDatePicker": true,
                "timePicker": true,
                "minYear": 2020,
                "timePicker24Hour": true,
                "autoApply": false,
                "locale": {
                    "format": "DD.MM.YYYY hh:mm",
                    "separator": " - ",
                    "applyLabel": "Apply",
                    "cancelLabel": "Cancel",
                    "fromLabel": "From",
                    "toLabel": "To",
                    "customRangeLabel": "Custom",
                    "weekLabel": "W",
                    "daysOfWeek": [
                        "Su",
                        "Mo",
                        "Tu",
                        "We",
                        "Th",
                        "Fr",
                        "Sa"
                    ],
                    "monthNames": [
                        "January",
                        "February",
                        "March",
                        "April",
                        "May",
                        "June",
                        "July",
                        "August",
                        "September",
                        "October",
                        "November",
                        "December"
                    ],
                    "firstDay": 1
                }
            },
            dateRangePicker: {
                "minYear": 2020,
                "autoApply": false,
                "locale": {
                    "format": "DD.MM.YYYY",
                    "separator": " - ",
                    "weekLabel": "W",
                    "applyLabel": "Apply",
                    "cancelLabel": "Cancel",
                    "fromLabel": "From",
                    "toLabel": "To",
                    "customRangeLabel": "Custom",
                    "daysOfWeek": [
                        "Su",
                        "Mo",
                        "Tu",
                        "We",
                        "Th",
                        "Fr",
                        "Sa"
                    ],
                    "monthNames": [
                        "January",
                        "February",
                        "March",
                        "April",
                        "May",
                        "June",
                        "July",
                        "August",
                        "September",
                        "October",
                        "November",
                        "December"
                    ],
                    "firstDay": 1
                },
                "minDate": "04/01/2020",
                "opens": "left"
            },
            singleDatePicker: {
                "singleDatePicker": true,
                "timePicker": false,
                "minYear": 2020,
                "timePicker24Hour": true,
                "autoApply": false,
                "locale": {
                    "format": "DD.MM.YYYY",
                    "separator": " - ",
                    "applyLabel": "Apply",
                    "cancelLabel": "Cancel",
                    "fromLabel": "From",
                    "toLabel": "To",
                    "customRangeLabel": "Custom",
                    "weekLabel": "W",
                    "daysOfWeek": [
                        "Su",
                        "Mo",
                        "Tu",
                        "We",
                        "Th",
                        "Fr",
                        "Sa"
                    ],
                    "monthNames": [
                        "January",
                        "February",
                        "March",
                        "April",
                        "May",
                        "June",
                        "July",
                        "August",
                        "September",
                        "October",
                        "November",
                        "December"
                    ],
                    "firstDay": 1
                }
            }
        },
        ru: {
            singleDatetimeRangePicker: {
                "singleDatePicker": true,
                "timePicker": true,
                "minYear": 2020,
                "timePicker24Hour": true,
                "autoApply": false,
                "locale": {
                    "format": "DD.MM.YYYY hh:mm",
                    "separator": " - ",
                    "applyLabel": "Подтвердить",
                    "cancelLabel": "Отмена",
                    "fromLabel": "C",
                    "toLabel": "По",
                    "customRangeLabel": "Custom",
                    "weekLabel": "W",
                    "daysOfWeek": [
                        "ВС",
                        "ПН",
                        "ВТ",
                        "СР",
                        "ЧТ",
                        "ПТ",
                        "СБ"
                    ],
                    "monthNames": [
                        "Январь",
                        "Февраль",
                        "Март",
                        "Апрель",
                        "Май",
                        "Июнь",
                        "Июль",
                        "Август",
                        "Сентябрь",
                        "Октябрь",
                        "Ноябрь",
                        "Декабрь"
                    ],
                    "firstDay": 1
                }
            },
            dateRangePicker: {
                "minYear": 2020,
                "autoApply": false,
                "locale": {
                    "format": "DD.MM.YYYY",
                    "separator": " - ",
                    "applyLabel": "Подтвердить",
                    "cancelLabel": "Отмена",
                    "fromLabel": "C",
                    "toLabel": "По",
                    "customRangeLabel": "Custom",
                    "weekLabel": "W",
                    "daysOfWeek": [
                        "ВС",
                        "ПН",
                        "ВТ",
                        "СР",
                        "ЧТ",
                        "ПТ",
                        "СБ"
                    ],
                    "monthNames": [
                        "Январь",
                        "Февраль",
                        "Март",
                        "Апрель",
                        "Май",
                        "Июнь",
                        "Июль",
                        "Август",
                        "Сентябрь",
                        "Октябрь",
                        "Ноябрь",
                        "Декабрь"
                    ],
                    "firstDay": 1
                },
                "minDate": "04/01/2020",
                "opens": "left"
            },
            singleDatePicker: {
                "singleDatePicker": true,
                "timePicker": false,
                "minYear": 2020,
                "timePicker24Hour": true,
                "autoApply": false,
                "locale": {
                    "format": "DD.MM.YYYY",
                    "separator": " - ",
                    "applyLabel": "Подтвердить",
                    "cancelLabel": "Отмена",
                    "fromLabel": "C",
                    "toLabel": "По",
                    "customRangeLabel": "Custom",
                    "weekLabel": "W",
                    "daysOfWeek": [
                        "ВС",
                        "ПН",
                        "ВТ",
                        "СР",
                        "ЧТ",
                        "ПТ",
                        "СБ"
                    ],
                    "monthNames": [
                        "Январь",
                        "Февраль",
                        "Март",
                        "Апрель",
                        "Май",
                        "Июнь",
                        "Июль",
                        "Август",
                        "Сентябрь",
                        "Октябрь",
                        "Ноябрь",
                        "Декабрь"
                    ],
                    "firstDay": 1
                }
            }
        },
        fr: {
            singleDatetimeRangePicker: {
                "singleDatePicker": true,
                "timePicker": true,
                "minYear": 2020,
                "timePicker24Hour": true,
                "autoApply": false,
                "locale": {
                    "format": "DD.MM.YYYY hh:mm",
                    "separator": " - ",
                    "weekLabel": "W",
                    "applyLabel": "Appliquer",
                    "cancelLabel": "Annuler",
                    "fromLabel": "Depuis",
                    "toLabel": "À",
                    "customRangeLabel": "Coutume",
                    "daysOfWeek": [
                        "Di",
                        "Lu",
                        "Ma",
                        "Me",
                        "Je",
                        "Ve",
                        "Sa"
                    ],
                    "monthNames": [
                        "Janvier",
                        "Février",
                        "Mars",
                        "Avril",
                        "Mai",
                        "Juin",
                        "Juillet",
                        "Août",
                        "Septembre",
                        "Octobre",
                        "Novembre",
                        "Décembre"
                    ],
                    "firstDay": 1
                }
            },
            dateRangePicker: {
                "minYear": 2020,
                "autoApply": false,
                "locale": {
                    "format": "DD.MM.YYYY",
                    "separator": " - ",
                    "weekLabel": "W",
                    "applyLabel": "Appliquer",
                    "cancelLabel": "Annuler",
                    "fromLabel": "Depuis",
                    "toLabel": "À",
                    "customRangeLabel": "Coutume",
                    "daysOfWeek": [
                        "Di",
                        "Lu",
                        "Ma",
                        "Me",
                        "Je",
                        "Ve",
                        "Sa"
                    ],
                    "monthNames": [
                        "Janvier",
                        "Février",
                        "Mars",
                        "Avril",
                        "Mai",
                        "Juin",
                        "Juillet",
                        "Août",
                        "Septembre",
                        "Octobre",
                        "Novembre",
                        "Décembre"
                    ],
                    "firstDay": 1
                },
                "minDate": "04/01/2020",
                "opens": "left"
            },
            singleDatePicker: {
                "singleDatePicker": true,
                "timePicker": false,
                "minYear": 2020,
                "timePicker24Hour": true,
                "autoApply": false,
                "locale": {
                    "format": "DD.MM.YYYY",
                    "separator": " - ",
                    "weekLabel": "W",
                    "applyLabel": "Appliquer",
                    "cancelLabel": "Annuler",
                    "fromLabel": "Depuis",
                    "toLabel": "À",
                    "customRangeLabel": "Coutume",
                    "daysOfWeek": [
                        "Di",
                        "Lu",
                        "Ma",
                        "Me",
                        "Je",
                        "Ve",
                        "Sa"
                    ],
                    "monthNames": [
                        "Janvier",
                        "Février",
                        "Mars",
                        "Avril",
                        "Mai",
                        "Juin",
                        "Juillet",
                        "Août",
                        "Septembre",
                        "Octobre",
                        "Novembre",
                        "Décembre"
                    ],
                    "firstDay": 1
                }
            }
        },
    };

    let languageCode = getCurrentLocale();

    $('.date-range-picker').daterangepicker(
        languageSettings[languageCode].dateRangePicker || languageSettings.en.dateRangePicker
        , function (start, end, label) {
            console.log('New date range selected: ' + start.format('YYYY-MM-DD') + ' to ' + end.format('YYYY-MM-DD') + ' (predefined range: ' + label + ')');
        });

    $('.single-date-picker').daterangepicker(
        languageSettings[languageCode].singleDatePicker || languageSettings.en.singleDatePicker
    )

    $('.date-range-picker-edit-phone-licence').daterangepicker(
        languageSettings[languageCode].singleDatetimeRangePicker || languageSettings.en.singleDatetimeRangePicker
    )
});
