$(document).ready(function () {

    window.calculateAndSetPrice = function () {
        window.calculateLicencePrices()
        $("input#own_payment_count").attr("value", $("span#finalPrice").text())
    };

    $(".payment-amount-radio").on('click', function () {
        $('.payment-amount-radio').css('border', '1px solid gray')
        $(this).css('border', '2px solid #17a2b8')
        $('#own_payment_count').val($(this).parents('.form-check-inline').find('input').val())
    });

    $("#custom_own_payment").on('click', function () {
        $("#own_payment_count").show().prop("disabled", false)
    });

    $(".standart-payment").on('click', function () {
        $("#own_payment_count").hide().prop("disabled", true)
    });

    $("#own_payment_count").on('change', function () {
        $("#custom_own_payment").attr("value", $("#own_payment_count").val())
    });

    $("#price_calculator").on('change', function () {
        if ($('#price_calculator').is(':checked')) {
            $('.price_calculator').show(400)
            $('#licence_count').removeAttr('disabled')
            calculator.one_licence_price = parseInt($("#ph-price").text())
            if ($("#annual-switch").is(':checked')) {
                calculator.one_licence_month_price = parseInt($("#ph-price-month").text())
            } else {
                calculator.one_licence_month_price = 0
            }
            window.calculateAndSetPrice()
            calculator.setVoiceStorageValues()
        } else {
            $('.price_calculator').hide(400)
            $('#licence_count').attr('disabled', 'disabled')
        }
    });

    $("#annual-switch").on('change', function () {
        $('#month_tariff_tariff_id').toggle()
        $('#annual_tariff_tariff_id').toggle()
    })

    $("#month_tariff_tariff_id").on('change', function () {
        $('#annual_tariff_tariff_id').val($('#month_tariff_tariff_id').val())
    })

    $("#annual_tariff_tariff_id").on('change', function () {
        $('#month_tariff_tariff_id').val($('#annual_tariff_tariff_id').val())
    })

    $("#annual-switch, #month_tariff_tariff_id, #annual_tariff_tariff_id").on('change', function () {
        if ($('#month_tariff_tariff_id').is(":hidden")) {
            $("#ph-price").html(parseInt($("#annual_tariff_tariff_id option:selected").text().replace('/sim', '')) * 12)
            $("#ph-price-month").html(parseInt($("#month_tariff_tariff_id option:selected").text().replace('/sim', '')) * 12)

        } else {
            $("#ph-price").html($("#month_tariff_tariff_id option:selected").text().replace('/sim', ''))
            $("#ph-price-month").html(parseInt($("#month_tariff_tariff_id option:selected").text().replace('/sim', '')) * 12)
        }
        $("#price_calculator").trigger('change')
    })

    $("input[name=licence_count]").on('change', function () {
        window.calculateAndSetPrice()
    });

    $('#with_prolongation').on('change', function () {
        window.calculateAndSetPrice()
    });

    $('#with_voice_storage').on('change', function () {
        window.calculateAndSetPrice()
    });
})
